//Prod
//
//const SNS = 'qewgk-faaaa-aaaan-qenoa-cai';
//

const NEMESIS = '7mgsn-kiaaa-aaaan-qmpoa-cai';   //LATEST score improvements with dfx 0.20.1
const ICARUS = 'cru6d-taaaa-aaaan-qmz6q-cai';    //LATEST rank improvements with dfx 0.23.0
const PROMETHEUS = 'ojqt2-syaaa-aaaan-qltvq-cai';
const ATLAS = 'uvs73-vqaaa-aaaan-ql2qq-cai';     

export const QUERIO_SE_CANISTER_ID = ICARUS;
export const QUERIO_SE_HOST = 'https://icp0.io';

//Dev
//export const QUERIO_SE_CANISTER_ID = 'bkyz2-fmaaa-aaaaa-qaaaq-cai';
//export const QUERIO_SE_HOST = 'http://127.0.0.1:4943';

//Treasury
export const QUERIO_FUNDED = 4750;
export const QUERIO_PRESALE = 40612;
export const QUERIO_SEED_FUNDERS_BACKEND_CANISTER_ID = '5nnys-tyaaa-aaaan-qemeq-cai';
export const QRO_TOKEN_CANISTER_ID = 'vi5vh-wyaaa-aaaan-qizxa-cai';
export const QRO_BRIDGE_CANISTER_ID = 'uhxr6-qiaaa-aaaan-qmosa-cai';

//Bridge
export const FEE = '0.002'; //~6 USD

//export const MINIMUM_QRO_BALANCE = 5; //Sepolia
//export const QRO_TOKEN = '0x77ed861491e409fef9083c5cd1fb65fe63f7fc52'; //Sepolia
//export const QRO_BRIDGE = '0x79F00c499B74cfAE64233BbbB850ca59805441ca'; //Sepolia

export const MINIMUM_QRO_BALANCE = 500; //Mainnet
export const QRO_TOKEN = '0xFeA156A736dEe69da8740185F7d38e14f2d99AE7'; //Mainnet
export const QRO_BRIDGE = '0x6bdEb7b48f50A019aBfd9C5B5c2979917c69B8E8'; //Mainnet



